//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';
// import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { formatData, prepareFormData, getValue, formatDate } from '../contexts/Utils';
import { useNotification } from '../components/UseNotification';
import Api from '../contexts/Api';
import Formfields from '../components/Formfields';
import { PenaltiesList } from '../contexts/Contexts'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MissionDetailDrivers from '../components/MissionDetail/MissionDetailDrivers';
import MissionDetailRecap from '../components/MissionDetail/MissionDetailRecap';
import { Box, Modal } from '@mui/material';
import MissionDetailViews from '../components/MissionDetail/MissionDetailViews';
import styled from 'styled-components';
import Button from '../components/BaseComponents/Button';
import MissionFiles from '../components/MissionDetail/MissionFiles';
import MissionDetailDriver from '../components/MissionDetail/MissionDetailDriver';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionDetail = (props) => {
  const { uuid } = useParams();
  const [mission, setMission] = useState({});
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const [penaltiesModalOpen, setPenaltiesModalOpen] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [dateModalOpen, setDateModalOpen] = useState(false);
  const [urgent, setUrgent] = useState(false)
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;
  const { notification } = useNotification();
  const todayDate = new Date().toISOString().split('T')[0];
  const navigate = useNavigate();

  //
  // ─── HANDLE DIALOG STATE ───────────────────────────────────────
  //
  const handleClickOpen = (opt) => {
    if (opt.type === 'cancel') {
      setCancelDialog(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = (opt) => {
    if (opt.type === 'cancel') {
      setCancelDialog(false);
    } else {
      setOpen(false);
    }
  };

  const penaltyPrice = () => {
    //
    // ─── CHECK IF DATE IS WITHIN 48H TO SET THE PENALTY PRICE ───────────────────────────────────────
    //
    let price = 50;
    if (mission.date_type === 2) {
      const then = new Date(mission.date);
      const now = new Date();
      const msBetweenDates = Math.abs(then.getTime() - now.getTime());
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

      if (hoursBetweenDates < 48) {
        price = 100;
      }
    }
    return price;
  };

  //
  // ─── GET MISSION DETAIL ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchMissions = async () => {
      const getMissions = await Api({
        endpoint: `/missions?user_id=${user_id}&uuid=${uuid}&detail=true${props.user_mission || (props.role === 'driver' && props.history)
          ? `&user_mission=${props.user_mission}`
          : ''
          }${props.to_validate || (['garage', 'super-admin'].includes(props.role) && props.history)
            ? `&company_id=${props.role === 'super-admin' ? 'all' : user_id}`
            : ''
          }${!props.search ? '&driver_infos=true' : ''}${props.role === 'super-admin' ? '&admin_user=true' : ''}`,
        method: 'GET',
      });
      if (getMissions.success && getMissions.data && Object.keys(getMissions.data).length) {
        setMission(getMissions.data);
        props.role === 'driver' && await Api({ endpoint: '/missions/views', data: { mission_id: getMissions.data.uuid } })
      }
    };
    if (!Object.keys(mission).length) {
      fetchMissions();
    }
  }, [mission, uuid, user_id, props.user_mission, props.role, props.history, props.to_validate, props.search]);

  //
  // ─── PENALTIES MANAGEMENT ───────────────────────────────────────
  //
  const handlePenalities = async () => {
    const formData = prepareFormData({ formId: 'penalities_form' });

    if (formData.errors) {
      setErrors(formData.errors);
      return;
    }

    if (formData.fields) {
      const data = { company_id: mission.company_id, mission_id: mission.uuid, mission_ref: mission.reference, driver_id: mission.driver_id, ...formData.fields };
      await Api({
        endpoint: `/penalties`,
        data: {
          fields: data,
          uuid,
        }
      });
    }
    setPenaltiesModalOpen(false);
    handleMissionBook({ uuid: mission.uuid, status: 6, mission_status: 6, company: true })
  }

  //
  // ─── BOOK MISSION ───────────────────────────────────────
  //
  const handleMissionBook = async (opt) => {
    let data = { uuid: opt.uuid, status: opt.status };

    if (opt.company) {
      data['company_id'] = user_id;
    }

    if ([1, 6, 7].includes(opt.status)) {
      data['company_id'] = mission.company_id
    }

    if (validationModalOpen) {
      const formData = prepareFormData({ formId: 'validation_form' });
      data = { ...data, ...formData.fields }
    }

    if (opt.status === 1) {
      data.referent = user_id
    }

    const bookMission = await Api({
      endpoint: `/missions`,
      method: 'PUT',
      data: {
        fields: { ...data },
        uuid,
        from_detail: true
      }
    });

    notification({
      variant: bookMission.success ? 'success' : 'error',
      message: bookMission.message,
    });

    if (bookMission.success) {
      setMission({
        ...mission,
        ...{
          status: opt.mission_status,
          driver_id: user_id,
        },
      });

      setValidationModalOpen(false);
    }
    // Close dialog if open
    if (open) {
      handleClose({ type: 'delete' });
    }
  };

  //
  // ─── ADD DRIVERS ON MISSION ───────────────────────────────────────
  //
  const handleMissionDriver = async () => {
    const data = prepareFormData({ formId: 'date_form' });
    if (data.errors) {
      setErrors(data.errors);
      return;
    }

    let errorFields = {};
    ['recovery_date', 'delivery_date', 'recovery_delivery_date'].map((field) => {
      const enteredDate = Date.parse(data.fields[field]);
      const minDate = Date.parse(mission.vehicle_availability);

      if (enteredDate < minDate) {
        let message = `La date ne peux pas être inférieure au ${formatDate({ time: mission.vehicle_availability, display: 'date' })}`
        errorFields[field] = { message }
      }
    })
    if (Object.keys(errorFields).length) {
      setErrors(errorFields)
      return;
    }

    let userInfos = props.user;

    let drivers_proposal = {
      user_id,
      recovery_date: data.fields.recovery_date,
      delivery_date: data.fields.delivery_date,
      recovery_delivery_date: data.fields.recovery_delivery_date,
      name: userInfos.name,
      firstname: userInfos.firstname,
      email: userInfos.email,
      urgent: +urgent
    }

    const params = {
      endpoint: `/missions`,
      method: 'PUT',
      data: { fields: { drivers_proposal }, uuid, from_detail: true },
    };

    const createMission = await Api(params);
    notification({ variant: createMission.success ? 'success' : 'error', message: createMission.message || "Vous êtes sur la liste d'attente" });
    setMission({ ...mission })
    navigate('/');
    createMission.success && setDateModalOpen(false);
  }

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      // maxWidth: 1200,
      // margin: 'auto auto 40px',
      // padding: 20,
    },
    btnContainer: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'flex-start',
      gap: 20,
    },
    editBtn: {
      background: 'var(--light-grey) !important',
    },
    validationModal: {
      position: 'absolute',
      background: '#fff',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '25px',
      width: '350px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& h2': {
        marginBottom: "35px",
        alignSelf: "center"
      }
    },
    modalInput: {
      display: 'flex',
      marginBottom: '10px',
      gap: 10
    },
    validationForm: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '10px'
    },
    dateModal: {
      '& h2': {
        marginTop: '0'
      }
    },
    penaltiesModal: {
      width: '500px',
      maxHeight: '500px',
      overflowY: 'auto',

      '& h2': {
        marginTop: 0,
      }
    },
    recoveryTitle: {
      fontSize: '2em',
      color: 'var(--primary-color)'
    },
    noResult: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: 'auto',
      height: 'calc(100vh - 200px)',
      width: 'fit-content',
      '& img': {
        width: 400,
      },
      '& span': {
        textAlign: 'center',
        marginTop: 10,
        fontSize: '1.1em',
      },
    },
  });
  const classes = useStyle();

  const checkFacebookMessenger = document.querySelector('.facebookMessengerScript');
  if (!checkFacebookMessenger && mission.facebook_page_id && props.role === 'driver' && mission.driver_id === user_id) {
    let facebookMessenger = document.createElement('div');
    facebookMessenger.setAttribute('class', 'facebookMessengerScript');
    document.body.appendChild(facebookMessenger);
    let containerFacebook1 = document.createElement('div');
    containerFacebook1.id = 'fb-root';
    facebookMessenger.appendChild(containerFacebook1);
    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: !0,
        version: 'v14.0',
      });
    };
    let containerFacebook2 = document.createElement('div');
    containerFacebook2.setAttribute('class', 'fb-customerchat');
    containerFacebook2.setAttribute('page_id', mission.facebook_page_id);
    facebookMessenger.appendChild(containerFacebook2);
    let js = document.createElement('script');
    js.id = 'facebook-jssdk';
    js.src = 'https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js';
    facebookMessenger.appendChild(js);
  }

  let formatedData = formatData({ data: mission });

  const noResult = <div className={classes.noResult}>
    <img alt="Mission déjà attribuée" src="/assets/img/no_result.svg" />
    <span>Mission déjà attribuée</span>
  </div>

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    Boolean(Object.keys(mission).length) ? (
      <>
        {(!mission.driver_id || (mission.driver_id === user_id) || props.role === 'super-admin' || props.role === 'garage') ? <div className={classes.wrapper}>
          <Modal
            open={validationModalOpen}
            onClose={() => setValidationModalOpen(false)}
          >
            <Box className={classes.validationModal}>
              <h2>Validation de la mission</h2>
              <form id='validation_form' className={classes.validationForm}>
                <Formfields field={{ component: 'text', name: 'distance', label: 'Distance (km)', default: mission.distance, required: true, error: getValue(errors, ['distance', 'message']) }} />
                <div className={classes.modalInput}>
                  <Formfields field={{ component: 'text', name: 'price', label: 'Prix (€)', default: mission.price, required: true, error: getValue(errors, ['price', 'message']) }} />
                  <Formfields field={{ component: 'text', name: 'price_driver', label: 'Prix convoyeur (€)', default: mission.price_driver ? mission.price_driver : (mission.price - (mission.price * 0.33)).toFixed(2), required: true, error: getValue(errors, ['price_driver', 'message']) }} />
                </div>
              </form>
              <Button
                variant="primary"
                sx={{ marginTop: '20px' }}
                onClick={() => {
                  handleMissionBook({ uuid: mission.uuid, status: 1 });
                }}>
                Valider la mission
              </Button>
            </Box>
          </Modal>
          <Modal
            open={dateModalOpen}
            onClose={() => setDateModalOpen(false)}
          >
            <Box className={`${classes.validationModal} ${classes.dateModal}`}>
              <h2>Date de la récupération</h2>
              <form id='date_form' className={classes.validationForm}>
                <Formfields field={{ type: 'date', component: 'datepicker', name: 'recovery_date', label: "Date d'enlèvement", size: '100%', defaultValue: mission.date ? mission.date.split('T')[0] : todayDate, required: true, error: getValue(errors, ['recovery_date', 'message']) }} />
                <Formfields field={{ type: 'date', component: 'datepicker', name: 'delivery_date', label: 'Date de livraison', size: '100%', defaultValue: mission.date ? mission.date.split('T')[0] : todayDate, required: true, error: getValue(errors, ['delivery_date', 'message']) }} />
                {Boolean(mission.recovery) && <Formfields field={{ type: 'date', component: 'datepicker', name: 'recovery_delivery_date', label: 'Date de livraison retour', size: '100%', defaultValue: mission.date ? mission.date.split('T')[0] : todayDate, required: true, error: getValue(errors, ['recovery_delivery_date', 'message']) }} />}

                <Formfields field={{ component: 'checkbox', name: 'urgent', label: "Je veux à tout prix cette mission", handleChange: setUrgent }} />
                {urgent && <span>En cochant cette case, j'accepte que mon montant perçu soit déduit de 10%</span>}
              </form>
              <Button style={{ marginTop: 20 }} onClick={handleMissionDriver}>Valider la proposition</Button>
            </Box>
          </Modal>
          <Modal
            open={penaltiesModalOpen}
            onClose={() => setPenaltiesModalOpen(false)}
          >
            <Box className={`${classes.validationModal} ${classes.penaltiesModal}`}>
              <h2>Pénalités</h2>
              <form id='penalities_form' className={classes.validationForm}>
                {PenaltiesList.map(e => <Formfields key={e.name} field={{ component: 'switch', textName: e.name, label: e.label, size: '100%', textfield: true, textLabel: "Montant (€)", required: true, textError: getValue(errors, [`${e.name}`, 'message']) }} />)}
              </form>
              <Button variant="primary" sx={{ marginTop: '35px' }} onClick={handlePenalities}>Clôturer la mission</Button>
            </Box>
          </Modal>
          <S.DuplicateContainer>
            <Button href={`/dupliquer/${uuid}`}>Dupliquer</Button>
          </S.DuplicateContainer>
          {Boolean(mission.recovery) && <S.MissionDetailTitle>Mission Aller</S.MissionDetailTitle>}
          <MissionDetailRecap mission={formatedData} role={props.role} />
          {Boolean(mission.recovery) && <>
            <S.MissionDetailTitle style={{ marginTop: 40 }}>Mission reprise</S.MissionDetailTitle>
            <MissionDetailRecap mission={formatData({ data: mission.recovery_mission })} recovery={true} role={props.role} data={mission} />
          </>}
          {(mission.status >= 4 || (props.role === 'super-admin' && mission.status == 3))  && <MissionFiles readonly={true} mission_id={mission.uuid} />}
          {
            <div className={classes.btnContainer}>
              {(props.role === 'super-admin' ||
                (props.role === 'garage' && mission.company_id === user_id)) &&
                !mission.del &&
                (mission.status < 4 || mission.status === 5) && (
                  <Button variant="error" onClick={() => handleClickOpen({ type: 'delete' })}>Supprimer</Button>
                )}
              {((props.role === 'super-admin' && mission.status <= 4) ||
                (props.role === 'garage' && mission.company_id === user_id && mission.status == 3)) &&
                !mission.del && (
                  <Button href={`/editer/${mission.uuid}`} variant="contained" className={classes.editBtn}>Modifier</Button>
                )}
              {props.role === 'driver' && mission.status < 4 && !mission.drivers_proposal && (
                <Button onClick={() => setDateModalOpen(true)}>Se proposer pour la mission</Button>
              )}
              {props.user_mission && [3, 4].includes(mission.status) && (
                <Button variant="error" onClick={() => {
                  mission.status === 3
                    ? handleMissionBook({ uuid: mission.uuid, status: 5, mission_status: 1 })
                    : handleClickOpen({ type: 'cancel' });
                }}>
                  Annuler
                </Button>
              )}
              {(props.role === 'super-admin' && (props.user_mission || props.to_validate)) && (
                <>
                  {((mission.status === 2 && props.user_mission) ||
                    (mission.status === 3 && props.to_validate)) && (
                      <Button variant="destructive" onClick={() => { handleMissionBook({ uuid: mission.uuid, status: 7, mission_status: 7 }) }}>Refuser</Button>
                    )}
                  {props.user_mission && mission.status === 2 && (
                    <Button onClick={() => { handleMissionBook({ uuid: mission.uuid, status: 4, mission_status: 4 }); }}>
                      Accepter la mission
                    </Button>
                  )}
                  {props.to_validate && mission.status === 3 && (
                    <Button onClick={() => setValidationModalOpen(true)}>
                      Valider la mission
                    </Button>
                  )}
                  {['super-admin'].includes(props.role) && mission.status === 4 && (
                    <Button onClick={() => setPenaltiesModalOpen(true)}>
                      Terminer la mission
                    </Button>
                  )}
                </>
              )}
            </div>
          }

          {/* Delete mission confirmation dialog */}
          <Dialog
            open={open}
            onClose={() => handleClose({ type: 'delete' })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Êtes-vous sur de vouloir supprimer la mission ?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Attention, cette action est irréversible la mission ne pourra pas être restaurée.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose({ type: 'delete' })}>Annuler</Button>
              <Button
                onClick={() =>
                  handleMissionBook({ uuid: mission.uuid, status: 7, mission_status: 7 })
                }
                autoFocus
              >
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={cancelDialog}
            onClose={() => handleClose({ type: 'cancel' })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Êtes-vous sur de vouloir annuler la mission ?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{`Attention, une pénalité de ${penaltyPrice()}€ sera appliquée.`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose({ type: 'cancel' })} color="error">Annuler</Button>
              <Button
                onClick={() => {
                  handleMissionBook({ uuid: mission.uuid, status: 5, mission_status: 1 });
                  handleClose({ type: 'cancel' });
                }}
                autoFocus
                variant="contained"
              >
                Valider
              </Button>
            </DialogActions>
          </Dialog>
          {props.role === 'super-admin' && <MissionDetailDrivers mission_id={uuid} mission={mission} />}
          {props.role === 'super-admin' && <MissionDetailViews mission_id={uuid} />}
          {props.role === 'driver' && <MissionDetailDriver mission_id={uuid} mission={mission} />}
        </div > : noResult}
      </>
    ) : noResult
  );
};

export default MissionDetail;


const S = {};

S.MissionDetailTitle = styled.h2`
  color:var(--primary);
  font-size:40px;
  margin-top: 0;
`
S.DuplicateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`