import React, { useEffect, useState } from "react";
import Router from "./contexts/Router";
import { useSelector } from 'react-redux';
import Api from './contexts/Api';
import Authenticate from "./views/Authenticate";
import { useLocation, useNavigate } from "react-router-dom";
import EstimationModal from "./components/Modals/EstimationModal";
import Sidebar from "./components/Sidebar";
import styled from "styled-components";
import Header from "./containers/Header/Header";
import Notifications from "./components/BaseComponents/Notifications";
import { getValue } from "./contexts/Utils";

const App = () => {
  // 
  // ─── INIT STATES AND REDUX ELEMENTS ───────────────────────────────────────
  //
  const [authenticated, setAuthenticated] = useState({ status: false });
  const auth = useSelector((state) => state.auth.values);
  const storedData = JSON.parse(localStorage.getItem('saint_bernard_auth'));
  const navigate = useNavigate();

  // 
  // ─── REMOVE FACEBOOK MESSENGER CHAT IF NOT IN MESSION DETAIL ───────────────────────────────────────
  //
  const location = useLocation();
  if ((!location.pathname.includes('/mission-en-attente/') && !location.pathname.includes('/mission/'))) {
    const checkFacebookMessenger = document.querySelector('.facebookMessengerScript');
    if (checkFacebookMessenger) {
      checkFacebookMessenger.remove()
    }
  }

  let notLogedUrl = ['/connexion', '/inscription', '/mot-de-passe-oublie', '/reinitialiser-le-mot-de-passe']

  // 
  // ─── MANAGE APP CONNECTION ───────────────────────────────────────
  //
  useEffect(() => {

    // 
    // ─── CHECK AUTHENTICATION ───────────────────────────────────────
    //
    const authenticateUser = async () => {
      const params = {
        data: { "user_id": storedData.user_id },
        endpoint: '/auth/authenticate',
      }

      const isAuthenticated = await Api(params)

      if (isAuthenticated && isAuthenticated.success) {
        Promise.all([
          Api({ endpoint: `/data?user_id=${storedData.user_id}`, method: 'GET' }),
          Api({ endpoint: `/settings?user_id=${storedData.user_id}`, method: 'GET' })
        ])
          .then(([app_data, settings]) => {
            window._DATA = app_data.content
            window._DATA.settings = settings.data
            setAuthenticated({ status: true, user: isAuthenticated.user })
          });
      } else {
        setAuthenticated({ status: 'failed' });

        if (!notLogedUrl.some(url => location.pathname.includes(url))) {
          navigate('/connexion');
        }
      }
    }

    // 
    // ─── IF USER IS LOGGED CHECK AUTHENTIFICATION ───────────────────────────────────────
    //
    if (storedData && storedData.token) {
      authenticateUser()
    } else {
      setAuthenticated({ status: false });

      if (!notLogedUrl.some(url => location.pathname.includes(url))) {
        navigate('/connexion');
      }
    }
  }, [auth])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  // if (!['gabriel.tresch@olwe.fr', 'r.gilles@saint-bernard-services.com'].includes(getValue(authenticated, ['user', 'email'])) && location.pathname !== '/connexion') {
  //   return <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', color: '#89CDA8' }}>
  //     <h1>En maintenance</h1>
  //   </div>
  // }

  return <>
    {window && window.google && <EstimationModal partner_custom={authenticated ? (getValue(authenticated, ['user', 'partner_custom']) || '{}') : {}} partner={getValue(authenticated, ['user', 'partner']) || 1} />}
    {
      !authenticated.status && authenticated.status !== 'failed' && storedData ? <div>Loader</div> :
        !authenticated.status || authenticated.status === 'failed' ? <Authenticate /> : <>
          <>
            <Sidebar user={authenticated.user} />
            <S.Layout>
              {/* <EstimationModal partner={authenticated.user.partner} /> */}
              <S.Sticky>
                <Notifications user={authenticated.user} />
                <Header user={authenticated.user} />
              </S.Sticky>
              <Router user={authenticated.user} />
            </S.Layout>
          </>
        </>
    }
  </>
}

export default App;

const S = {};

S.Layout = styled.div`
  margin: 20px 20px 20px 280px;

  @media (max-width: 900px) {
    margin: 20px;
  }
`

S.Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`